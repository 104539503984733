import React from 'react'
import { graphql } from 'gatsby'
import { DefaultPageWithContent } from '../../../components/DefaultPage'
import injectNestedIntlProvider from '../../../components/NestedIntlProvider'

const PrivacyStatement = () => (
  <DefaultPageWithContent code="privacy_statement" />
)
export default injectNestedIntlProvider(PrivacyStatement)

export const query = graphql`
  query($language: String!) {
    messages: allKeyValue(
      filter: {
        file: { relativeDirectory: { eq: "pages" }, name: { eq: $language } }
        key: {
          regex: "/^pages\\.privacy_statement/"
        }
      }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
